@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap);

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.lato {
  font-family: "lato";
}

.left {
  float: left;
}

.comfortaa {
  font-family: "comfortaa";
}

.menu-text {
  font-size: large;
}

.innerImageFrame {
  display: flex;
}

/* border frame for art */
.art {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: solid 1.8vmin #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  text-align: center;
}
